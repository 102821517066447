<template>
    <div class="notice list pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column py-3 px-3 mb-3">
            <pre class="small desc white-space-pre-line" v-html="desc"></pre>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'',
            desc:'',
            terms:[
                {
                    idx:0,
                    title:'운영 정책',
                    desc: `(차후 추가될 예정입니다.)

Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis sit dolorum molestiae. Vel, reprehenderit quis facere beatae corrupti odio voluptates sapiente saepe tempore tempora neque non optio iure, in maxime eveniet deserunt accusantium odit dicta culpa a autem. Cupiditate nulla molestias harum, quisquam id fugit fuga est quaerat doloribus quod dolores debitis perspiciatis provident, minima obcaecati odio deleniti. Iste in aliquam quibusdam minus, libero nihil aperiam harum quidem reprehenderit, dicta voluptas nisi sit facere delectus consectetur. Ducimus autem, nostrum sint distinctio voluptatibus voluptatem praesentium illo velit ea nobis! Fuga tenetur magnam architecto distinctio, at cumque quam aperiam soluta aspernatur ad.`,
                },
                {
                    idx:1,
                    title:'이용자 약관 정보',
                    desc: `제1조 (목적)
이 약관은 주식회사 번다싱어(이하 회사라 한다)가 제공하고 운영하는 서비스를 이용함에 있어 이용조건 및 절차, 이용자의 권리/의무 및 책임사항과 기타 필요한 사항을 규정하는 것을 목적으로 합니다.

제2조 (용어의 정의)
이 약관에서 사용하는 용어는 다음과 같이 정의됩니다.
1.	회사 : 주식회사 번다싱어가 운영하는 온라인 서비스 혹은 사업자의 의미로도 사용하며, 이용자에게 제반 서비스를 제공하는 자를 말합니다.
2.	이용자 : 이 약관에 따라 회사가 제공하는 서비스를 이용(재화 구매도 포함, 이하 같다)하는 회원 및 비회원을 말합니다.
3.	회원 : 회사에 개인정보를 제공하여 이용계약을 체결하거나, 아이디(ID)를 부여받은 개인으로 회사가 제공하는 정보를 지속적으로 제공 받으며 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
4.	비회원 : 회원이 아니면서 회사가 제공하는 제반 서비스를 이용하는 자를 말합니다.
5.	아이디(ID) : 회원의 식별과 제반 서비스 이용을 위하여 회원이 정하고 회사가 승인한 이메일(E-mail) 주소을 말합니다.
6.	비밀번호(PASSWORD) : 회원이 부여받은 아이디(ID)와 일치되는 회원임을 확인하고 회원의 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.
7.	이용권구매 :  이 약관에 따른 유료 서비스를 이용하기 위하여 활용되는 이용권을 회사와 제휴가 된 쇼핑몰 등에서 구매하는 것을 말한다.
8.	콘텐츠 : 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지ㆍ영상 등(이들의 복합체를 포함한다)의 정보나 자료를 말합니다.

제3조 (회사정보 등의 제공)
이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법 및 기타 관련법령의 규정에 따릅니다.
1.	“회사”는 “이용자”가 쉽게 알 수 있도록 다음 각 호의 사항을 앱내 공지사항 게시판등에 게시합니다.
①	대표자의 성명 및 상호
②	개인정보취급방침
③	개인정보관리책임자의 성명 및 전화번호 등의 연락처
④	출력에 제한이 있는 이동통신단말기 등을 이용하여 거래하는 “회사”는 제1항 각호의 사항이 앱내 화면에 순차적으로 나타나도록 할 수 있습니다. 이 경우 대표자성명ㆍ상호 및 앱 이용 관련한 이용약관은 그 내용을 확인할 수 있는 방법을 화면에 나타나게 하는 것으로 대신할 수 있습니다.

제4조 (약관의 게시 등)
1.	“회사”는 “이용자”에게 이 약관을 제시하여 동의를 받으며, 앱내 개인정보 화면에서 이 약관을 게시합니다. 이 경우 제3조를 준용합니다
2.	“회사”는 “이용자”가 동의하기 전에 약관에 정하여져 있는 내용 중 회원자격의 제한 및 상실, 청약철회, 과오금의 환불, 이용제한, 해제ㆍ해지, 이용자에 대한 피해보상 등과 같은 중요한 내용을 이용자가 쉽게 알 수 있도록 굵고 큰 글씨 등으로 표시하고, “이용자”가 그 내용을 쉽게 알 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 설명합니다.
3.	“회사”는 제1항부터 제2~3항까지를 위반한 경우 이 약관의 전부 또는 일부를 계약의 내용으로 주장하지 못합니다.

제5조 (약관의 변경 등)
1.	“회사”는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 문화체육관광부장관이 정하는 「콘텐츠 이용자보호지침」, 기타 관계법령 또는 상관습에 위배하지 않는 범위에서 이 약관을 변경할 수 있습니다.
2.	“회사”가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 현행약관과 함께 서비스를 제공하는 앱에 그 적용일자 10일(“이용자”에게 불리한 변경 또는 중대한 사항의 변경은 30일) 이전부터 적용일자 이후 상당한 기간동안 공지하고, 기존 “회원”에게는 변경될 약관, 적용일자 및 변경사유(중요내용에 대한 변경인 경우 이에 대한 설명을 포함)를 전자우편주소로 발송합니다.
3.	“회사”가 약관을 변경할 경우에는 변경약관을 공지한 후 10일(“이용자”에게 불리한 변경 또는 중대한 사항의 변경은 30일) 동안 변경약관의 적용에 대한 “이용자”의 동의 여부를 확인합니다.
4.	“회사”는 제3항의 기간 동안 【”이용자”가 거절의 의사표시를 하지 않으면 동의한 것으로 간주하겠다】는 내용을 별도로 고지하였음에도 불구하고 “이용자”가 이 기간 내에 거절의사를 표시하지 않았을 경우 변경약관에 동의한 것으로 간주할 수 있습니다.
5.	“이용자”가 변경약관의 적용을 거절한 경우 “회사”는 변경전 약관에 따른 서비스 제공이 기술적, 영업적으로 가능한 때에 변경전 약관에 따라 서비스를 제공합니다. 다만, 변경전 약관에 따라 서비스를 제공하는 것이 기술적, 영업적으로 곤란한 경우 “회사”는 “이용자”의 손해를 배상하고, 콘텐츠 이용계약을 해지할 수 있습니다.
6.	“이용자”가 변경약관을 거절한 경우 또는 제4항에 따라 동의한 것으로 간주되는 경우 “이용자”는 “콘텐츠” 이용계약을 해지할 수 있습니다.

제6조 (약관의 해석)
이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 문화체육관광부장관이 정하는 「콘텐츠 이용자보호지침」, 기타 관계법령 및 상관습에 따릅니다.

제7조 (회원가입)
1.	회원가입은 “이용자”가 이 약관에 대하여 동의를 하고, 회원가입신청을 한 후 “회사”가 이 신청에 대하여 승낙함으로써 이루어집니다.
2.	“이용자”는 회원가입신청서에 다음 사항을 기재해야 합니다. 제1호부터 제3호까지의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다.
①	“이용자”의 성명
②	“아이디”와 “비밀번호”
③	핸드폰번호, 전자우편주소, 생년월일(외국인은 여권번호 등)
④	기타 “회사”가 필요하다고 인정하는 사항
3.	“회사”는 “이용자”의 회원가입신청시 주민등록번호를 제외한 휴대폰인증 등의 방법에 따라 본인 여부를 확인할 수 있습니다.
4.	“회사”는 제1항부터 제3항까지의 “이용자”의 회원가입신청에 대하여 승낙함을 원칙으로 합니다. 다만, “회사”는 다음 각 호의 어느 하나에 해당하는 사유가 있는 경우 “이용자”의 회원가입신청에 대하여 거절할 수 있습니다.
①	가입신청자가 이 약관에서 금지하고 있는 행위를 하여 회원자격을 상실한 적이 있는 경우
②	실명이 아니거나 타인의 명의를 이용한 경우
③	허위의 정보를 기재하거나, 제2항 제1호부터 제3호까지의 사항을 기재하지 않은 경우
④	“이용자”의 고의 또는 과실로 인하여 승낙이 불가능하거나 기타 회사의 규정을 위반하여 신청한 경우
⑤	법령에서 “콘텐츠”이용등급을 정하고 있고, 이용가능연령에 달하지 않은 “이용자”가 이용신청을 한 경우
5.	“회사”는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 가입신청의 승낙을 유보하거나 거절할 수 있습니다.
6.	제4항 또는 제5항에 따라 회원가입 신청에 대하여 유보하거나 거절한 경우 “회사”는 이를 신청자에게 즉시 알려야 합니다. 다만, “회사”가 고의 또는 과실없이 신청자에게 통지할 수 없는 경우에는 그러하지 않습니다.
7.	회원가입계약은 “회사”의 승낙이 “이용자”에게 도달한 시점에 성립합니다.

제8조 (만 14세 미만 이용자의 회원가입 등에 관한 특칙)
1.	“회사”는 만 14세 미만의 “이용자”(이하 “아동”이라고 한다)의 회원가입신청에 대한 승낙을 하기 전에 부모 등 법정대리인(이하 “법정대리인”이라고 한다)의 동의를 받습니다. 다만, “법정대리인”의 동의를 받을 수 없는 경우 “아동”의 회원가입신청에 대하여 거절합니다.
2.	“회사”는 회원가입을 신청하는 “아동”에게 “법정대리인”의 동의를 받기 위하여 필요한 “법정대리인”의 성명 등 최소한의 정보를 요구할 수 있습니다.
3.	“아동”의 “법정대리인”은 “아동”의 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 동의를 철회한 경우 “회사”는 지체 없이 수집한 “아동” 및 “법정대리인”의 개인정보를 파기하는 등 필요한 조치를 취합니다.

제9조 (회원정보의 변경)
1.	“회원”은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고, 수정할 수 있습니다.
2.	“회원”은 회원가입신청시 기재한 사항이 변경되었을 경우 직접 수정하거나 전자우편 기타 방법으로 “회사”에 대하여 그 변경사항을 알려야 합니다.
3.	“회사”는 제2항에 따라 “회원”으로부터 변경사항을 통지받은 경우 이에 따라 개인정보를 변경합니다.
4.	“회사”는 “회원”이 제2항에 따라 수정하지 않거나 이의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 책임을 지지 않습니다.

제10조 (“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무)
1.	“회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 제3자가 이를 이용하도록 하여서는 안 됩니다.
2.	“회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자에 의해 사용되고 있음을 안 경우에는 이를 즉시 “회사”에 통지하고, “회사”의 안내에 따라야 합니다.
3.	제2항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임을 지지 않습니다.

제11조 (“회원”에 대한 통지)
1.	“회사”가 “회원”에게 통지를 하는 경우 “회원”이 지정한 전자우편주소 등으로 합니다.
2.	“회사”는 “회원” 전체에게 통지를 하는 경우 10일 이상의 기간 동안 “회사” 앱의 초기화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 제5조에 따른 약관의 변경 또는 “회원” 본인과 관련된 중요한 사항에 대하여는 제1항에서 정한 통지를 합니다.

제12조 (회원탈퇴 및 자격 상실 등)
1.	“회원”은 “회사”에 언제든지 탈퇴를 요청할 수 있으며, “회사”는 즉시 회원탈퇴를 처리합니다.
2.	“회원”이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 일시적으로 제한 또는 정지시킬 수 있습니다. 이 경우 “회사”는 “이용자”에게 해당 사유를 통지합니다.
①	“회원”가입 또는 서비스 이용 신청 또는 변경시 허위내용의 기재
②	타인의 개인정보도용
③	“회사” 또는 제3자가 게시한 정보의 변경
④	“회사”가 금지한 정보(컴퓨터 프로그램 등)의 사용, 송신 또는 게시
⑤	“회사” 또는 제3자의 저작권 등 지식재산권에 대한 침해
⑥	“회사” 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
⑦	외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 “회사”의 사이트에 공개 또는 게시하는 행위
⑧	회사의 기술적 보호조치를 회피 혹은 무력화하는 행위
⑨	제21조 제3항에 따라 대금을 지급하지 않은 행위
⑩	기타 관계 법령에서 금지하는 행위 또는 공서양속에 반하는 행위
3.	“회원”자격이 제한 또는 정지되었던 “회원”이 제2항 각호에서 금지한 행위를 반복하거나 그 “회원”에게 시정을 요구하였음에도 불구하고 30일 이내에 그 사유가 시정되지 않은 경우 “회사”는 회원자격을 상실시킬 수 있습니다. 다만, “회원”자격이 제한 또는 정지된 후 6월이 경과한 경우에는 위반행위의 반복으로 인정하지 않습니다.
4.	“회사”가 제3항에 따라 “회원”자격을 상실시킨 경우 당해 “회원”에게 이의 사실과 사유를 통지합니다. 다만, 당해 “회원”이 이의 통지를 받은 날로부터 30일 이내에 소명하고, 그 내용이 정당한 경우 “회사”는 즉시 “회원”의 자격을 복구합니다.
5.	“회사”는 “회원”이 탈퇴하거나 회원자격을 상실하는 경우 “회원”의 개인정보를 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관계법령에서 정한 범위와 절차에 따라 파기합니다.

제13조 (개인정보보호)
1.	“회사”는 제7조 제2항의 신청서 기재사항 이외에 “이용자”의 제반 서비스 이용에 필요한 최소한의 정보를 “이용자”의 동의를 얻어 수집할 수 있습니다.
2.	“회사”가 “이용자”의 개인정보를 이용하려고 수집하는 때에는 다음 각 호의 모든 사항에 대하여 “이용자”에게 알려야 합니다. 다음 각 호의 어느 하나의 사항을 변경하려는 경우에도 이용자에게 통지합니다.
①	개인정보의 수집 이용목적
②	수집하는 개인정보의 항목
③	개인정보의 보유 및 이용기간
④	개인정보취급방침
⑤	개인정보관리책임자의 신원 (소속, 성명 및 전화번호 기타 연락처)
3.	“회사”는 “이용자”의 개인정보를 제3자에게 제공할 경우 다음 각 호의 사항을 “이용자”에게 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항을 변경하려는 경우에도 “이용자”의 동의를 받습니다.
①	개인정보를 제공받는 자의 신원 [성명, 주소, 전화번호 등]
②	제공받는 자의 개인정보 이용목적
③	제공하는 개인정보의 항목
④	개인정보를 제공받는 자의 개인정보 보유 및 이용기간
4.	“회사”는 “이용자”가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 당해 “이용자”의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 “회사”가 집니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 그러하지 않습니다.
①	통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
②	서비스 이용에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 명확하게 곤란한 경우
③	서비스 제공에 따른 요금정산을 위하여 필요한 경우
④	도용방지를 위하여 본인확인에 필요한 경우
⑤	법령에 의한 경우
5.	“이용자”는 언제든지 제1항 또는 제3항의 동의를 철회할 수 있습니다.
6.	“이용자”는 언제든지 “회사”가 가지고 있는 자신에 관한 개인정보, “회사”가 자신의 개인정보를 이용하거나 제3자에게 제공한 현황, “회사”에게 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 한 현황에 대한 열람이나 제공을 요구할 수 있고 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. “회사”는 “이용자”로부터 오류의 정정을 요구받으면 그 오류를 정정하거나 정정하지 못하는 사유를 “이용자”에게 알리는 등 필요한 조치를 하고, 필요한 조치를 할 때까지는 해당 개인정보를 이용하거나 제공하지 않습니다.
7.	“회사” 또는 그로부터 개인정보를 제공받은 자는 “이용자”가 동의한 범위 내에서 개인정보를 사용할 수 있으며, 목적이 달성된 경우에는 당해 개인정보를 지체 없이 파기합니다.
8.	“회사”는 「개인정보보호법」, 「정보통신망이용촉진 및 정보보호에 관한 법률」 등 관계 법령이 정하는 바에 따라 “이용자”의 개인정보를 보호합니다.
제14조 (서비스에 관한 정보제공 및 이용계약의 성립 등)
1.	“회사”는 서비스이용계약을 체결하기 전에 “이용자”가 서비스 등에 관하여 정확하게 이해하고, 실수 또는 착오 없이 거래할 수 있도록 각 호에서 규정한 사항을 제공합니다.
①	“회사”의 대표자의 성명 및 상호, 전자우편주소 등
②	재화 및 서비스의 제작 연월일
③	재화 및 서비스의 제공자 성명(법인인 경우에는 법인의 명칭), 주소 및 전화번호
④	재화 및 서비스의 명칭 및 내용
⑤	재화 및 서비스의 이용에 있어서 이용제한연령등급이 있는 경우 그 등급
⑥	재화 및 서비스의 이용기간
⑦	재화 및 서비스의 대금(가격이 결정되어 있지 아니한 경우에는 그 결정의 구체적인 방법), 지급방법 및 지급시기
⑧	재화 및 서비스의 제공 방법 및 시기
⑨	청약철회의 행사가능 여부 및 행사의 방법
⑩	재화 및 서비스의 교환ㆍ반품ㆍ보증과 그 대금 환불의 조건 및 절차
⑪	서비스이용약관(사이버몰의 경우 초기화면에 “이용자”가 연결화면을 통하여 약관을 볼 수 있도록 조치할 수 있으며, 출력에 제한이 있는 이동통신단말기 등을 이용하여 거래하는 “회사”는 “이용자”가 약관의 내용을 확인할 수 있는 방법을 화면에 나타나게 할 수 있습니다)
2.	재화 및 서비스의 이용에 필요한 최소한의 기술사양에 관한 사항으로 다음 각 목에서 정한 사항. 다만, 재화 및 서비스의 특성상 이러한 정보가 불필요한 경우에는 그러하지 않습니다.
①	컴퓨터 등 정보처리장치의 중앙처리장치(CPU) 속도
②	운영체제(OS) 등 시스템 소프트웨어(SW) 사양
③	서비스 이용에 필수적인 소프트웨어(SW)
④	램(RAM) 용량
⑤	하드드라이브 등 서비스 관련 저장장치의 유효용량
⑥	화소 등 화면 사양
⑦	기타 서비스 이용에 필수적인 하드웨어 장치
⑧	정보통신망 필수 전송속도 및 전송량
⑨	기술적 보호조치(DRM)에 관한 사항
⑩	모바일 서비스의 경우 모바일서비스의 이용에 적합한 모바일 기기의 종류 등에 관한 정보
⑪	이용자”의 피해보상, 재화 및 서비스에 대한 불만 및 “이용자”와 “회사”간 분쟁처리에 관한 사항
⑫	기타 서비스 이용계약의 체결과 관련하여 필요한 사항
3.	“회사”는 “이용자”는 서비스 이용계약에 대한 신청을 완료하기 전에 그 내용을 확인 및 정정할 수 있는 절차를 제공합니다.
4.	”회사”는 “이용자”의 이용신청이 다음 각 호의 어느 하나에 해당하는 경우 이용신청에 대하여 거절하거나 승낙을 유보할 수 있습니다. 다만, “회사”는 제1호부터 제3호까지에 해당하는 “이용자”의 이용신청에 대하여 거절합니다.
①	실명이 아니거나 타인의 명의를 이용한 경우
②	허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우
③	이용제한연령등급이 있는 서비스에 대하여 해당 연령에 달하지 못한 “이용자”가 이용신청을 한 경우
④	서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우
5.	“회사”의 승낙이 “이용자”에게 도달한 시점에 서비스 이용계약은 성립합니다.
6.	“회사”는 “이용자”의 서비스 이용계약에 관한 신청에 대하여 승낙한 경우 지체없이 제1항 각 호의 사항이 모두 기재된 계약서 1부를 교부합니다.

제15조 (미성년 “이용자”의 서비스 이용계약의 취소 등)
1.	“회사”는 만 20세 미만의 미성년 “이용자”가 유료 서비스를 이용하고자 하는 경우에 “법정대리인”이 그 계약에 대하여 동의하지 않거나, 계약체결 후 추인을 하지 않으면 미성년 “이용자” 본인 또는 “법정대리인”이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지합니다. 다만, 민법 등에 따라 미성년 “이용자”가 단독으로 계약을 체결할 수 있는 경우에는 그러하지 않습니다.
2.	미성년 “이용자”가 유료 서비스를 이용하고자 신청할 때 결제대금의 합산액이 “회사”에서 정하는 월별 일정금액을 초과하는 경우에는 반드시 “법정대리인”의 동의를 받습니다.
3.	“회사”는 미성년 “이용자”가 “회원”가입시 제2항에서 규정하고 있는 월별 일정금액을 고지합니다.

제16조 (“회사”의 의무)
1.	“회사”는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실히 합니다.
2.	“회사”는 “이용자”가 안전하게 재화를 구매하고 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며, 개인정보보호정책을 공시하고, 준수합니다.
3.	“회사”는 “이용자”가 서비스 이용 및 그 대금결제내역을 언제든지 확인할 수 있도록 조치합니다.
4.	“회사”는 제반 서비스의 이용과 관련하여 “이용자”로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하며, 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 “이용자”에게 통지합니다.
5.	회사는 정보통신망법, 통신비밀호보법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.

제17조 (“이용자”의 의무)
1.	“이용자”는 제12조 제2항 각호에서 정한 행위를 하여서는 안 됩니다.
2.	“이용자”는 이 약관의 규정, 이용안내 및 “회사”가 서비스 이용과 관련하여 공지한 주의사항 또는 고지사항 등을 준수하여야 합니다.

제18조 (서비스의 제공 및 중단)
1.	“회사”는 서비스 이용계약에 따라 “이용자”가 서비스를 이용할 수 있도록 연중무휴, 1일 24시간 동안 필요한 서비스를 제공하는 것을 원칙으로 합니다.
2.	“회사”는 서비스를 일정 영역으로 나누어 각 영역별로 이용 가능시간을 별도로 정할 수 있으며, 이 경우 사전에 공지를 통해 “이용자”에게 그 사실을 알립니다.
3.	“회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 전부 또는 일부의 이용을 일시적으로 중단할 수 있습니다. 이 경우 “회사”는 제11조에서 정한 방법으로 “이용자”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
4.	“회사”는 서비스의 이용이 일시적으로 중단됨으로 인하여 “이용자”가 입은 손해에 대하여 배상 또는 보상합니다. 다만, “회사”가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.
5.	“회사”는 서비스의 이용에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검을 실시하기 24시간 이전에 제11조에서 정한 방법으로 이용자에게 통지합니다. 이 경우 정기점검시간은 제11조에서 정한 방법에 따라 통지한 시간으로 합니다.
6.	사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에 “회사”는 제11조에서 정한 방법으로 “이용자”에게 통지하고, “회사”가 정한 바에 따라 “이용자”에게 보상합니다. 다만, “회사”가 보상기준 등을 고지하지 아니하거나, 고지한 보상기준이 적절하지 않은 경우에는 「콘텐츠이용자보호지침」에 따라 “이용자”에게 보상합니다.

제19조 (서비스 이용조건 등의 변경)
1.	“회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 서비스 이용조건 등을 변경할 수 있습니다.
2.	“회사”는 서비스의 내용, 이용방법, 이용시간 등을 변경할 경우에 변경사유, 변경될 서비스의 내용 및 제공일자 등을 그 변경 전 10일(중대하거나 이용자에게 불리한 경우는 30일) 이상 사이버몰의 초기화면 또는 해당 서비스 이용초기화면에 게시합니다.
3.	제2항의 경우에 변경될 내용이 중대하거나 “이용자”에게 불리한 경우에 “회사”는 해당 서비스를 이용하는 “이용자”에게 제11조에서 정한 방법으로 통지하고, 동의를 받습니다. 이때, “회사”는 동의를 거절한 “이용자”에 대하여는 변경전 서비스를 제공합니다. 다만, 동일한 서비스의 제공이 불가능할 경우 “회사” 또는 “이용자”는 계약을 해지할 수 있습니다.
4.	“회사”는 제1항에 의한 서비스 이용조건 등의 변경 및 제3항에 의한 계약의 해지로 인하여 “이용자”가 입은 손해에 대하여 배상합니다. 다만, “회사”가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.

제20조 (정보의 제공 및 광고의 전송)
1.	“회사”는 “이용자”가 서비스 이용과 관련하여 필요한 정보를 공지사항이나 전자우편 등의 방법으로 “이용자”에게 제공할 수 있습니다. 다만, “이용자”는 언제든지 전자우편 등을 통하여 수신을 거절할 수 있습니다.
2.	“회사”는 서비스 제공과 관련하여 서비스 이용초기화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 다만, 광고가 게재된 전자우편 등을 수신한 “이용자”는 언제든지 전자우편 등을 통하여 “회사”에게 수신거절을 할 수 있습니다.
3.	“회사”는 제1항 또는 제2항 단서에 따라 “이용자”가 수신을 거절한 경우 정보 또는 광고를 “이용자”의 전자우편주소 등으로 전송하지 않습니다. 다만, 이 약관상 허용되는 것으로 해석되는 조항의 경우이거나 기타 “이용자”의 서비스이용과 관련하여 불가피한 경우 “회사”는 제1항 단서에 따른 수신거절에도 불구하고 “이용자”의 전자우편주소로 관련 정보를 전송할 수 있습니다.
4.	“회사”가 제2항의 광고를 전화 및 모사전송기기에 의하여 “이용자”에게 전송하기 위하여는 “이용자”의 사전 동의를 받습니다.

제21조 (게시물)
1.	게시물에 대한 모든 책임과 권리는 이를 게시한 이용자에게 있습니다. 특히 회사가 서비스하고 있는 노래자랑 등에 대하여 이용자가 본 서비스를 이용할 때, 게시판에 올려지는 모든 게시물은 제3자에 의하여 제작되었으므로 그에 따른 권리와 법적 책임은 원칙적으로 해당 콘텐츠의 제작자에게 있습니다.
①	콘텐츠의 의존가치여부, 정확성과 사실성과 더불어 콘텐츠가 이용자에게 혐오적이지 않고, 또 이용자가 반감 없이 받아들일 수 있는 가를 판단하는 것도 해당 콘텐츠의 제작자에 있습니다.
②	회사는 해당 콘텐츠의 접속을 이용자에 대한 서비스 차원에서 제공할 뿐이며, 이에 대한 어떠한 법적 책임도 지지 않습니다.
③	회사와 제3자를 통해서 제공되는 콘텐츠는 이용자가 회사의 사용조건과 유의사항을 준수하는 것을 전제로 제공됩니다.
④	게시판에 게시된 내용에 대한 저작권 분쟁시 책임은 해당 콘텐츠를 제작하여 게시한 회원 또는 비회원에게 있습니다.
2.	회사는 회원이 서비스에 게시, 게재하거나 서비스를 통해 전송한 내용물에 대하여 책임지지 않으며, 아래 사항에 해당하는 경우 삭제할 수 있습니다.
①	타인을 비방(욕설)하거나, 프라이버시를 침해하거나, 명예를 손상시키는 내용인 경우
②	서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 경우
③	범죄적 행위에 관련된다고 인정되는 내용인 경우 (저작권관련내용 포함)
④	회사의 지적재산권, 타인의 지적재산권 등 기타 권리를 침해하는 내용인 경우
⑤	기타 관계법령에 위반된다고 판단되는 경우

제22조 (게시물의 삭제)
1.	“회사”는 게시판에 「정보통신망이용촉진 및 정보보호 등에 관한 법률」을 위반한 청소년유해매체물이 게시되어 있는 경우에는 이를 지체없이 삭제합니다. 다만, 19세 이상의 “이용자”만 이용할 수 있는 게시판은 예외로 합니다.
2.	“회사”가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 “회사”에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 “회사”는 지체없이 필요한 조치를 취하고, 이를 즉시 신청인에게 통지합니다.

제23조 (저작권 등의 귀속)
1.	“회사”가 제작하거나 소유한 서비스에 대한 저작권 기타 지식재산권은 “회사”에 귀속합니다.
2.	“이용자”는 “회사”가 제공하는 서비스를 이용함으로써 얻은 정보 중 “회사” 또는 제공업체에 지식재산권이 귀속된 정보를 “회사” 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. 다만, 「저작권법」 및 「콘텐츠산업진흥법」 등에서 이를 허용한 경우에는 그러하지 않습니다.
3.	상기 ②항은 이용자와 회사와의 개별 약정을 통하여 대체할 수 있습니다.
4.	회원이 탈퇴를 하거나 회원 자격을 상실한 경우에 본인 계정에 기록된 게시물은 삭제됩니다. 다만 제3자에게 의하여 스크랩, 담기 등으로 다시 게시된 게시물, 제3자에 의하여 덧글이 첨부된 게시물 등 다른 이용자의 정상적인 서비스 이용에 필요한 게시물은 삭제되지 않습니다.
5.	회사는 회사의 합병, 영업양도, 회사가 운영하는 사이트간의 통합 등의 사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를 변경할 수 있습니다.
6.	“이용자”가 온라인서비스를 이용하면서 독자적으로 생성한 정보 및 서비스에 대하여는 “이용자”가 저작권 등의 권리를 가집니다. “회사”는 “이용자”의 저작물을 사용하고자 하는 경우 당해 “이용자”의 허락을 받습니다.

제23조 (“이용자”의 청약철회)
1.	“회사”와 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.
2.	제1항의 청약철회권은 다음 각 호의 어느 하나에 해당할 경우 행사할 수 없습니다. 다만, “회사”는 제2호부터 제4호까지에 해당하여 “이용자”의 청약철회권을 배제하기 위하여 【청약철회를 할 수 없다】는 사실을 서비스 또는 그 포장 기타 “이용자”가 쉽게 알 수 있는 곳에 표시하거나 시용상품을 제공하거나 서비스의 한시적 또는 일부 이용이 가능하게 하는 등의 방법으로 청약철회권의 행사가 방해받지 아니하도록 조치를 취합니다.
①	내용을 확인하기 위하여 포장 등을 훼손한 경우를 제외하고, “이용자”의 책임 있는 사유로 서비스 및 재화 등이 멸실 또는 훼손된 경우
②	“이용자”의 사용 또는 일부 소비에 의하여 서비스 및 재화 등의 가치가 현저히 감소한 경우
③	시간의 경과에 의하여 재판매가 곤란할 정도로 서비스 및 재화 등의 가치가 현저히 감소한 경우
④	같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
⑤	이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “회사”는 청약 철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표기합니다.
3.	제1항 및 제2항에도 불구하고 “이용자”는 다음 각 호의 어느 하나에 해당하는 사유가 있을 때에는 당해 서비스 및 재화 등을 공급받은 날로부터 3월 이내 또는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 서비스 이용계약에 대한 청약을 철회할 수 있습니다
①	표시ㆍ광고된 내용과 다른 서비스가 제공된 경우
②	계약내용과 다르게 이행된 경우
4.	“이용자”는 제1항 또는 제3항의 규정에 의한 청약의 철회를 전화, 휴대전화메세지, 전자우편, 모사전송 등으로 할 수 있습니다.
5.	“이용자”가 제1항 또는 제3항의 규정에 의한 청약철회권을 서면(전자문서 포함)으로 행사하는 경우 그 의사표시가 기재된 서면을 발송한 날에 그 효력이 발생합니다.
6.	“회사”는 청약철회의 의사표시를 수신한 후 지체 없이 이의 수신사실을 “이용자”에게 회신합니다.

제24조 (이용자”의 계약해제 해지)
1.	“이용자”는 다음 각 호의 하나의 사유가 있는 경우 서비스 이용계약을 해제 또는 해지할 수 있습니다.
①	콘텐츠 또는 서비스가 제공시기까지 제공되지 않은 경우
②	하자있는 콘텐츠 또는 서비스의 제공으로 인하여 “이용자”가 이용목적을 달성할 수 없는 경우
③	1개월 동안 서비스의 이용 중지·장애발생 누적시간이 72시간을 초과한 경우
④	이 약관에 따라 변경약관을 거절하거나 동의한 것으로 간주되는 경우
⑤	이 약관에 따라 서비스이용조건 등의 변경사유 등을 게시하지 않거나 제32조 제3항에 따라 “이용자”가 거절한 경우
2.	서비스 이용계약기간이 1개월 이상이며, “이용자”가 「방문판매 등에 관한 법률」제2조 제10호에 해당하는 소비자인 경우 “이용자”는 언제든지 서비스 이용계약을 해지할 수 있습니다.
3.	“이용자”가 제1항 제1호 또는 제2호의 사유로 서비스 이용계약을 해제 또는 해지하기 위하여는 상당한 기간을 정하여 “회사”에 대하여 서비스의 이용을 가능하게 하거나 하자없는 서비스를 제공할 것을 요구하여야 합니다. 다만, “이용자”가 당해 제공시기에 서비스를 제공받지 아니하면 그 계약의 목적을 달성할 수 없는 경우 또는 “회사”가 사전에 서비스를 제공하지 않을 것을 명시한 경우에는 그러하지 않습니다.

제25조 (회사의 계약해제 해지 및 이용제한)
1.	“회사”는 “이용자”가 제12조 제2항 각호에서 정한 행위를 하였을 경우 사전통지 후 기간을 정하여 서비스의 이용을 제한할 수 있습니다.
2.	“회사”는 다음 각 호의 어느 하나에 해당하는 경우 서비스 이용계약을 해제 또는 해지할 수 있습니다.
①	제1항에 따라 서비스의 이용이 제한되었던 “이용자”가 제12조 제2항 각호에서 정한 행위를 한 경우. 다만, 이용제한이 된 시점이 6월을 경과한 경우에는 그러하지 않습니다.
②	“회사”가 제12조 제2항 각호에서 정한 행위를 한 “이용자”에게 시정을 요구하였음에도 불구하고, 30일 이내에 그 사유가 시정되지 않은 경우
③	제2항의 해제·해지는 제11조 제1항에서 정하고 있는 방법에 따라 “이용자”에게 그 의사표시가 도달한 때에 효력이 발생합니다.
④	“이용자”는 “회사”의 해제?해지 및 이용제한에 대하여 이의신청을 할 수 있습니다. “이용자”의 이의신청이 정당한 경우 “회사”는 즉시 “이용자”가 서비스를 이용할 수 있도록 필요한 조치를 취합니다.

제26조 (서비스의 중단)
1.	회사는 다음에 해당하는 경우 서비스 제공의 일부 또는 전부를 제한하거나 중지할 수 있습니다.
①	서비스용 설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우
②	전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
③	전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우
④	설비 장애 또는 이용 폭주 등으로 서비스 이용에 지장이 있는 경우
⑤	파업이나 태업 등 기타 회사 내부의 불가피한 사유가 발생한 경우
2.	회사가 제1항의 규정에 의하여 서비스 제공의 일부 또는 전부를 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 고지합니다.

제27조 (콘텐츠 또는 서비스의 하자 등에 의한 이용자의 손해에 대한 배상)
1.	“회사”는 콘텐츠 또는 서비스의 하자, 이용중지 또는 장애 등에 의하여 발생한 “이용자”의 손해에 대하여 보상 또는 배상합니다.
2.	제1항에 따른 손해배상 등의 방법 및 절차는 「콘텐츠이용자보호지침」에 따라 처리합니다.

제28조 (면책사유)
1.	“회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 콘텐츠 또는 서비스를 제공할 수 없는 경우에 “이용자”에게 발생한 손해에 대하여 책임을 지지 않습니다.
2.	“회사”는 “이용자”의 책임있는 사유로 인한 콘텐츠 또는 서비스 이용 장애에 대하여 책임을 지지 않습니다.
3.	“회사”는 “이용자” 또는 제3자가 게시판에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대하여 책임을 지지 않습니다.
4.	“회사”는 “이용자” 상호간 또는 “이용자”와 제3자 간에 콘텐츠 또는 서비스를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다

제29조 (보안관리)
회사는 이용자의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보에 최대한 필요한 기술적 조치를 강구합니다.

제30조 (분쟁의 해결)
1.	“회사”는 “이용자”가 제기하는 의견이나 불만에 대하여 적절하고 신속하게 처리하고, 그 결과를 통지합니다. 다만, 신속한 처리가 곤란한 경우에 “회사”는 “이용자”에게 그 사유와 처리일정을 통보합니다.
2.	“회사”는 “이용자”가 제기한 의견 등이 정당하지 않음을 이유로 처리하지 않은 경우 이의 사유를 통보합니다.
3.	“회사”와 “이용자” 사이에 분쟁이 발생한 경우 “회사” 또는 “이용자”는 「콘텐츠산업진흥법」 제29조에서 정하고 있는 콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수 있습니다.

제31조 (재판관할)
1.	“회사”와 “이용자”간의 서비스 이용계약에 관한 소송은 제소 당시의 “이용자”의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다.
2.	제소 당시 “이용자”의 주소 또는 거소가 분명하지 아니한 경우에 “회사”와 “이용자”간의 서비스 이용계약에 관한 소송에 관한 관할법원은 「민사소송법」에 따라 정합니다.


본 약관은 2021년 1월 1일부터 시행합니다. 
`,
                },
                {
                    idx:2,
                    title:'개인정보 처리방침',
                    desc: `주식회사 번다싱어(www.bundasinger.co.kr)(이하 회사라 한다)는 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

이 개인정보처리방침은 2024년 11월 01일부터 적용됩니다.

제1조 (개인정보의 처리 목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1.	홈페이지 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.

2.	민원사무 처리
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.

3. 재화 또는 서비스 제공
서비스 제공, 콘텐츠 제공, 본인인증, 연령인증 등을 목적으로 개인정보를 처리합니다.

4. 마케팅 및 광고에의 활용
신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.

제2조(개인정보의 처리 및 보유 기간)
1. 앱 회원가입 및 관리
앱 회원가입 및 관리와 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거
관련법령: 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
          소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
          대금결제 및 재화 등의 공급에 관한 기록 : 5년
          계약 또는 청약철회 등에 관한 기록 : 5년
          표시/광고에 관한 기록 : 6개월
예외사유 : 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.

2. 민원사무 처리
민원사무 처리와 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거 (전항과 동일)

3. 마케팅 및 광고에의 활용
마케팅 및 광고에의 활용과 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거 (전항과 동일)

제3조 (개인정보의 제3자 제공)
1.	회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하여, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」, 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
2.	회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.

제공받는 자의 개인정보 이용목적 : 이메일, 휴대폰번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키 제공받는 자의 보유·이용기간 : 1년

제4조 (개인정보처리 위탁)
1.	회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
2.	회사는 위탁계약 체결 시 「개인정보 보호법」제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적, 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
3.	위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

제5조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)
1.	정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
2.	제1항에 따른권리 행사는 주식회사 번다싱어에 대해 「개인정보 보호법」시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 번다싱어는 이에 대해 지체 없이 조치하겠습니다.
3.	제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
4.	개인정보 열람 및 처리정지 요구는 「개인정보 보호법」, 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
5.	개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
6.	회사는 정보주체 관리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

제6조 (처리하는 개인정보의 항목 작성)
1.	회사는 다음의 개인정보 항목을 처리하고 있습니다.
A.	홈페이지 회원가입 및 관리
필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠기

제7조 (개인정보의 파기)
1.	회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
2.	정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속보전하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3.	개인정보 파기의 절차 및 방법은 다음과 같습니다.
A.	파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
B.	파기방법 : 전자적 파일 형태의 정보는 기록을 재상할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

제8조 (개인정보의 안전성 확보 조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
1.	개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
2.	해킹 등에 대비한 기술적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
3.	개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
4.	개인정보에 대한 접근 제한
개인정보를 처리하는데이터베이스 시스템에 대한 접근권한의부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
5.	문서보안을 위한 잠금 장치 사용
개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다
6.	비인가 자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립, 운영하고 있습니다.

제9조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)
1.	회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.
2.	쿠기는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.

쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
쿠키의 설치·운영 및 거부 : 웹 브라우저 상단의 도구>인터넷옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

제10조 (개인정보 보호책임자)
1.	회사는 개인정보 처리에 관하 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

개인정보 보호책임자
성명 : 
직급/직책 : 
연락처 : 
이메일 : 
2.	정보주체께서는 회사의 서비스 또는 사업을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

제11조 (개인정보 열람청구)
정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

제12조 (권익침해 구제방법)
1.	정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의신고, 상담에 대하여는 아래의기관에 문의하시기 바랍니다.

개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
개인정보침해신고센터 : (국번없이) 118 (priacy.kisa.or.kr)
대검찰청 : (국번없이) 1301 (www.spo.go.kr)
검찰청 : (국번없이) 182 (cyberbureau.police.go.kr)
2.	「개인정보 보호법」제35조, 제36조, 제37조의규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는바에 따라 행정심판을 청구할 수 있습니다.

행정심판에 대한 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.

이 개인정보처리방침은 2024년 11월 01일부터 적용됩니다.`,
                },
                {
                    idx:3,
                    title:'E-mail 및 SMS 광고성 정보 동의',
                    desc:`1.	광고성 정보의 이용목적
주식회사 번다싱어(이하 ‘회사’라 한다)가 제공하는 이용자 맞춤형 서비스, 뉴스레터 발송, 새로운 기능의 안내, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편, 문자(SMS), 푸시 등을 통해 이용자에게 제공합니다. 이메일 및 SMS 수신거부와 관계없이 광고나 영리성 목적 외의 약관 안내 및 서비스 내용, 회사의 주요 정책 관련 변경에 따른 안내 등 의무적으로 안내되어야 하는 메일은 정상적으로 발송됩니다.

마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도 회사가 제송하는 서비스의 이요에 제한이 되지 않습니다, 단, 이벤트 및 이용자 맞춤형 서비스 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.

수신거부로 변경하여도 수정 전에 예약발송 메일 또는 SMS가 설정되어 있어 약 일주일 동안은 메일 또는 SMS가 발송될 수 있습니다. 이점 양해 부탁드립니다.

2.	개인정보 수집·이용 및 마케팅 정보 수신 동의
A.	개인정보 수집·이용 동의

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 style='border-collapse:collapse;border:none'>
    <tr>
        <td width=283 style='border:solid windowtext 1.0pt; padding:0 .5em 0 .5em;' align="center">수집·이용 목적
        </td>
        <td width=189 style='width:5.0cm;border:solid windowtext 1.0pt;border-left:
 none;padding:0 .5em; height:64px' align="center">수집·이용 항목
        </td>
        <td width=124 style='width:92.9pt;border:solid windowtext 1.0pt;border-left:
 none;padding:0 .5em; height:64px' align="center">보유·이용 기간
        </td>
    </tr>
    <tr style='height:64px'>
        <td width=283 style='width:212.4pt;border:solid windowtext 1.0pt;border-top:
 none;padding:0 .5em; height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>전자적 전송매체 <span lang=EN-US>(SMS, MMS, E-mail, </span>푸쉬 알람 등 <span lang=EN-US>)</span>를 통해 회사 서비스 및 제 <span lang=EN-US>3</span>자의 상품 또는 서비스에 대한 광고성 정보를 전송 </span>
            </p>
        </td>
        <td width=189 style='width:5.0cm;border-top:none;border-left:none;border-bottom:
 solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0 .5em; 
 height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>이메일 <span lang=EN-US>, </span>휴대폰번호 <span lang=EN-US>, </span>서비스 이용 정보 <span lang=EN-US>, </span>사용한 미디어에 대한 정보 </span>
            </p>
        </td>
        <td width=124 rowspan=4 style='width:92.9pt;border-top:none;border-left:none;
 border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
 padding:0 .5em; height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>회원 탈퇴 시까지 또는 법정 보유 기간</span>
            </p>
        </td>
    </tr>
    <tr style='height:64px'>
        <td width=283 style='width:212.4pt;border:solid windowtext 1.0pt;border-top:
 none;padding:0 .5em; height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>귀하의 <span lang=EN-US>SNS, </span>사이트 또는 당사에 제공한 귀하의 제안 <span lang=EN-US>, </span>의견 <span lang=EN-US>, </span>피드백 또는 컨텐츠 <span lang=EN-US>(</span>오디오 <span lang=EN-US>, </span>비디오 등 포함 <span lang=EN-US>)(</span>통칭하여 <span lang=EN-US>“</span>피드백 <span lang=EN-US>”)</span>를 <span lang=EN-US>(</span>회사의 재량에 따라 <span lang=EN-US>) </span>사이트의 다른 사용자 및 회사의 사업 <span lang=EN-US>, </span>제품 또는 서비스를 마케팅 또는 전시하거나 <span lang=EN-US>, </span>회원을 확보하기 위한 홍보 또는 판촉 목적으로 <span lang=EN-US>, </span>또는 대중의 구매 결정에 도움이 될 수 있는 피드백 정보를 대중에 제공하거나 대중에 대한 정보 제공 및 기타 목적으로 공유 또는 공개 </span>
            </p>
        </td>
        <td width=189 style='width:5.0cm;border-top:none;border-left:none;border-bottom:
 solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0 .5em; 
 height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>피드백 정보 <span lang=EN-US>, </span>성명 </span>
            </p>
        </td>
    </tr>
    <tr style='height:64px'>
        <td width=283 style='width:212.4pt;border:solid windowtext 1.0pt;border-top:
 none;padding:0 .5em; height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>인보이스 발행</span>
            </p>
        </td>
        <td width=189 style='width:5.0cm;border-top:none;border-left:none;border-bottom:
 solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0 .5em; 
 height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>성명 <span lang=EN-US>, </span>핸드폰번호 <span lang=EN-US>, </span>이메일 </span>
            </p>
        </td>
    </tr>
    <tr style='height:64px'>
        <td width=283 style='width:212.4pt;border:solid windowtext 1.0pt;border-top:
 none;padding:0 .5em; height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>상담시 본인확인 등</span>
            </p>
        </td>
        <td width=189 style='width:5.0cm;border-top:none;border-left:none;border-bottom:
 solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0 .5em; 
 height:64px'>
            <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;'>
                <span style='font-size:9.0pt;'>본인확인값 <span lang=EN-US>(CI, DI) </span>나이 <span lang=EN-US>, </span>연령대 </span>
            </p>
        </td>
    </tr>
</table>

3.	마케팅 정보 수신 동의
귀하는 당사가 전자적 전송매체(SMS, MMS, E-mail 등 다양한 전송매체)를 통해 당사 서비스 및 제3자의 상품 또는 서비스에 대한 광고성 정보를 전송하는 것에 동의합니다.


시행일자 : 2023년 00월 00일`
                }
            ]
            
        }
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        let index = parseInt(this.$route.params.idx);
        this.title = this.terms[index].title;
        this.desc = this.terms[index].desc;
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>