<template>
  <div class="basic_set_list pb-res">
    <ul class="basic_list d-flex flex-wrap flex-column p-3">
      <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between mb-3 position-relative">
          <div class="left d-flex">
              <!-- <img :src="item.art" class="border ratio ratio-1x1 img-fluid" alt="album art" style="width:70px;"> -->
              <div class="album_info d-flex flex-column justify-content-center pe-3">
                  <small class="text-body text-opacity-75"><span class="small">{{ item.date }}</span></small>
                  <span class="text-truncate">{{ item.name }}</span>
                  <small class="text-truncate text-opacity-50 text-body">{{ item.artist }}</small>
              </div>
          </div>
          <div class="right d-flex align-items-center flex-wrap justify-content-end">
              <div class="text-end text-blue w-100">{{item.point.toLocaleString()}}p</div>
              <div class="d-flex gap-2">
                  <button class="btn badge border"  @click="delayedAlert(item,true)" :class="{'checked':item.reserved}" variant="info"><i class="fal fa-clock fa-lg" :class="{'fas':item.reserved}"></i></button>
                  <button class="btn badge border" :class="{'checked':item.favorite}" @click="delayedAlert(item,false)"><i class="fal fa-star fa-lg" :class="{'fas':item.favorite}"></i></button>
              </div>
          </div>
      </li>
    </ul>
    <div class="alert alert-success d-flex align-items-center position-fixed max-w-px-768 start-50 translate-middle-x shadow bottom-0 mb-2" :class="{'alert-danger':alertType==='C'}" style="width:95%" role="alert"
        v-if="showAlert">
        <i class="fas fa-check me-2"></i> 
        <div v-if="alertType2==='reservation'">
            {{alertType==='C'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
        </div>
        <div v-else>
            {{alertType==='C'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            showAlert: false,
            alertType:'',
            alertType2:'',
        }
    },
    props:{
        list:{
            type:Array
        }
    },
    methods:{
        delayedAlert(item,boolean) {
            if(boolean){
                this.alertType2 = 'reservation'
                let arr = this.reservation_list;
                if(arr.includes(item)){
                    this.alertType = 'C'
                    let songIndex = arr.findIndex((element) => element === item);
                    this.$store.commit('removeReservation',songIndex)
                }else{
                    this.alertType = 'R'
                    this.$store.commit('reservation',item)
                }
            }else{
                this.alertType2 = 'favorite'
                let arr = this.favorite_list;
                if(arr.includes(item)){
                    this.alertType = 'C'
                    let songIndex = arr.findIndex((element) => element === item);
                    this.$store.commit('removeFavorite',songIndex)
                }else{
                    this.alertType = 'R'
                    this.$store.commit('addFavorite',item)
                }
            }         
            
            this.showAlert = true;
            setTimeout(() => {
                this.showAlert = false;
            }, 2000);
        },
    },
    computed:{
        reservation_list(){
            return this.$store.state.reserved_song
        },
        favorite_list(){
            return this.$store.state.favorite_song
        },
    }
}
</script>

<style lang="scss" scoped>


// galaxy fold 320 해상도 이하 기준
@media screen and (max-width:321px) {
  .basic_list>li{
    flex-wrap: wrap;
      .left{
          width:100% !important;
          max-width: 100%;
      }
      .left .album_info{
          max-width: initial;
          padding-right: 0 !important;
      }
      .right{
        padding-top: .5em !important;
        width: 100%;
        max-width: 100%;
        justify-content: space-between !important;
        flex-direction: row;
        flex-wrap: nowrap !important;
      }
      .right .text-end{
        text-align: left !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  }
}
</style>